import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Home from "./pages/home";
import Detail from "./pages/detail";
import Cart from "./pages/cart";
import ReviewCart from "./pages/review-cart";
import Checkout from "./pages/checkout";
import Header from "./components/header";
import Footer from "./components/footer";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";
import { LoginScreen } from './components/login'
import ScrollToTop from "./utils/scroll-to-top";

const Routes = () => {
    return (
      <Router>
        <Header />
        <ScrollToTop>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/terms" exact component={Terms} />
            <Route path="/privacy" exact component={Privacy} />
            <Route path="/detail/:id" component={Detail} />
            <Route path="/cart/:cartId" component={Cart}/>
            <Route path="/review/:cartId" component={ReviewCart} />
            <Route path="/checkout/:status" component={Checkout} />
            <Route path="/login" component={LoginScreen} />
            <Route path="*" component={Home} />
          </Switch>
          <Footer />
          </ScrollToTop>
        </Router>
    );
}

export default Routes;
