import _get from "lodash.get";
import { useEffect, useState } from "react";
import ApiUtils from "../utils/api-utils";

const useHeader = (locale) => {
  const [isLoading, setIsLoading] = useState(false);
  const [quickLinks, setQuickLinks] = useState();
  const [logoUrl, setLogoUrl] = useState("");
  const [categoryData, setCategoryData] = useState({});
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const results = await ApiUtils.doQuery(
          ApiUtils.queries.header(locale),
          ""
        );
        if (_get(results, "data")) {
          setQuickLinks(_get(results, "data.header.quickLinks", []));
          setLogoUrl(_get(results, "data.logoTheme.uploadLogo.url", ""));
          setCategoryData({
            products: _get(results, "data.header.chooseProductsIfAny", []),
            subCategory: _get(results, "data.header.defineSubCategories", []),
          });
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [locale]);
  return { quickLinks, categoryData, logoUrl, isLoading };
};

export default useHeader;
