import React, { useEffect } from 'react'
import { useLocaleContext } from '../../context/localeContext';
import Hooks from "../../hooks"
import {TT, LabelKeys} from "../../config/locale"
import { LOGIN_LOCALE_MAPPER } from "../../config/app-config"

const LoginScreen = ({ history, location }) => {
  const { locale } = useLocaleContext();

  const { storefrontWebAuth, authConstants, handleIsSignedIn } = Hooks.useAuth()
  const { AUTH0_DOMAINS, RESIZE, DONE, ERROR } = authConstants || {}

  useEffect(() => {
    const loginHandler = (e) => {
      const { origin, data } = e || {}
      const { message, value } = data || {}
      if (AUTH0_DOMAINS.includes(origin) && message === RESIZE) {
        const height = parseInt(value)
        if (!isNaN(height)) { 
          document.getElementById("login-iframe").style.height = `${height + 30}px`
        }
      } else if (origin !== window.location.origin) {
        console.error(origin + ' is not a valid origin')
        return
      }
      if (message === DONE) {
        if(storefrontWebAuth.isSignedIn()) {
          alert(TT(LabelKeys.LOGIN_SUCCESS, locale))
        };
        handleIsSignedIn()
        storefrontWebAuth.scheduleTokenRefresh()
        if(location.state) history.replace(location.state.userPath  === '/login'? '/': location.state.userPath)
        else history.replace('/')
        
      } else if (message === ERROR) {
        alert(TT(LabelKeys.LOGIN_FAILURE, locale))
        handleIsSignedIn()
        if(location.state) history.replace(location.state.userPath  === '/login'? '/': location.state.userPath)
        else history.replace('/')
      }
    }
    window.addEventListener('message', loginHandler, false)
    return () => window.removeEventListener('message', loginHandler, false)
  })

  return <div className="col-12 holds-the-iframe">
    {/* To override culture, storefrontWebAuth.getSignInUrl({ culture : 'de-DE' }) */}
    <iframe
      id="login-iframe"
      title="Login"
      src={storefrontWebAuth.getSignInUrl({culture: LOGIN_LOCALE_MAPPER[locale]})} 
      style={{ width: "100%", border: "none" }} 
    />
  </div>
}

export default LoginScreen