import _get from "lodash.get";
import { useEffect, useState } from "react";
import ApiUtils from "../utils/api-utils";

const useTermsAndCondition = (locale) => {
  const [isLoading, setIsLoading] = useState(false);
  const [termsAndCondition, setTermsAndCondition] = useState();
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const results = await ApiUtils.doQuery(
          ApiUtils.queries.termsAndCondition(locale),
          ""
        );
        if (_get(results, "data")) {
          setTermsAndCondition(_get(results, "data.termsAndCondition", {}));
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [locale]);
  return { termsAndCondition, setTermsAndCondition, isLoading };
};

export default useTermsAndCondition;
