import React from "react";
import { Link } from "react-router-dom";
import _get from "lodash.get";
import { LabelKeys } from "../config/locale/label-config";
import { TT } from "../config/locale";
import { LocaleContext } from "../context/localeContext";
import Loader from "../images/loader.svg";

class Checkout extends React.Component {
  static contextType = LocaleContext;
  constructor(props) {
    super(props);
  }
  render() {
    const locale = this.context.locale;
    let order = JSON.parse(sessionStorage.getItem("order"));
    let orderNo = _get(order, "orderNumber");
    const checkoutStatus = this.props.match.params.status;
    let paymentStatusElement;
    if (checkoutStatus === "success") {
      document.title = TT(LabelKeys.TITLE_ORDER_SUCCESSFUL, locale);
      paymentStatusElement = (
        <>
          {TT(LabelKeys.CHECKOUT_SUCCESS_MSG_1, locale)} <br />{" "}
          {TT(LabelKeys.CHECKOUT_SUCCESS_MSG_2, locale)}{" "}
        </>
      );
    } else if (checkoutStatus === "processing") {
      document.title = TT(LabelKeys.TITLE_PROCESSING_PAYMENT, locale);
      paymentStatusElement = (
        <>
          <div className="mt-5 mb-3">
            <img src={Loader} alt="" />
          </div>
          <br />
          <div className=" mb-3">
            {TT(LabelKeys.CHECKOUT_ORDER_CREATION, locale)}
          </div>
          <div className="mb-5">
            <br />
            {TT(LabelKeys.CHECKOUT_DO_NOT_CLOSE, locale)}
          </div>
        </>
      );
    } else paymentStatusElement = <></>;

    return (
      <div class="container mb-5">
        <div class="row">
          <div class="col-sm-9 m-auto">
            <div class="finish">
              <div class="finish-body">
                <div class="finish-title">
                  <h5>{paymentStatusElement}</h5>
                </div>
                {checkoutStatus === "success" && (
                  <>
                    <div class="finish-content">
                      <div class="finish-content-icon">
                        <i class="fal fa-handshake"></i>
                      </div>
                      {
                        <div class="finish-content-detail">
                          <span>
                            {TT(LabelKeys.CHECKOUT_ORDER_NO, locale)}
                            <b>{orderNo}</b>
                          </span>
                        </div>
                      }
                    </div>
                    <div class="finish-footer">
                      <Link to="/" class="btn btn-primary-custom">
                        {TT(LabelKeys.CHECKOUT_CONTINUE_SHOPPING, locale)}{" "}
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Checkout;
