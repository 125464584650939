import React, { useContext } from "react";
import { LabelKeys } from "../config/locale/label-config";
import { TT } from "../config/locale";
import { useLocaleContext } from "../context/localeContext";
import Hooks from "../hooks";
import CategoryDropDown from "./category-drop-down";
import CategoryDropDownMobile from "./category-drop-down-mobile";
import { AccountSettings } from './login'
import { Link, useHistory } from "react-router-dom";
import _get from "lodash.get";
import { CartContext } from "../context/cartContext";
import LocaleSelect from "./LocaleSelect";

const Header = () => {
  const { locale } = useLocaleContext();
  const history = useHistory()
  const { quickLinks, logoUrl, categoryData } = Hooks.useHeader(locale);
  const { isSignedIn } = Hooks.useAuth();
  const [globalCart] = useContext(CartContext);
  
  return (
    <div className="header" id="mainHeader">
      <div className="container-fluid">
        <div className="header-wrapper">
          <a href="/" rel="noreferrer" target="_self" className="header-logo">
            <img height="60px" alt="logo" src={logoUrl} />
          </a>
          <ul className="header-nav">
            <CategoryDropDown categoryData={categoryData}>
              <a>{TT(LabelKeys.HEADER_PRODUCTS, locale)}</a>
            </CategoryDropDown>
            {quickLinks &&
              quickLinks.map((navLink) => (
                <li>
                  <a href={navLink.title} key={navLink.title}>
                    {navLink.title}
                  </a>
                </li>
              ))}
          </ul>
          <div className="header-icon-nav">
            <button
              className="btn btn-light collapsed"
              data-toggle="collapse"
              data-target="#search-box"
              aria-controls="search-box"
            >
              <i className="far fa-search see"></i>
              <i className="fal fa-times closed"></i>
            </button>
            <button
              className={`btn btn-light collapsed ${
                _get(globalCart, "id") ? "" : "link-disabled"
              }`}
              data-toggle="collapse"
              onClick={() => { history.push(`/review/${_get(globalCart, "id")}`)}}
            >
              <i className="fal fa-shopping-bag see"></i>
              <span class="badge">{_get(globalCart, "lineItems.length")}</span>

            </button>
            <button
              className="btn btn-light collapsed"
              data-toggle="collapse"
              data-target="#locale-box"
              aria-controls="locale-box"
              style={{paddingLeft: '20px', paddingRight: '20px'}}
            >
              <i className="far fa-globe see">{` ${locale}`}</i>
              <i className="fal fa-times closed"></i>
            </button>
            <button
              className="btn btn-light collapsed"
              data-toggle="collapse"
              data-target="#login-box"
              aria-controls="login-box"
            >
              <i className={`fal ${isSignedIn? 'fa-address-card' : 'fa-user'} see`}></i>
              <i className="fal fa-times closed"></i>
            </button>
            <button
              className="btn btn-light collapsed btn-toggle mobile-toggled"
              data-toggle="collapse"
              data-target="#menu-box"
              aria-controls="menu-box"
            >
              <i className="fal fa-bars see"></i>
              <i className="fal fa-times closed"></i>
            </button>
          </div>
        </div>
      </div>
      <div
        className="header-box collapse fade"
        id="search-box"
        data-parent="#mainHeader"
      >
        <div className="header-box-body">
          <div className="container">
            <form action="">
              <div className="form-group">
                <label htmlFor="header-search" className="form-label">
                  {TT(LabelKeys.HEADER_SEARCH, locale)}
                </label>
                <input
                  type="text"
                  id="header-search"
                  className="form-control"
                  placeholder="Search..."
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <AccountSettings />
      <div
        className="header-box collapse fade"
        id="locale-box"
        data-parent='#mainHeader'
      >
        <div className="header-box-body" style={{padding: 0}}>
          <div className="container" style={{padding: 0}}>
              <div data-toggle="collapse" data-target="#locale-box"><LocaleSelect /></div>
          </div>
        </div>

      </div>
                
                {/* // : <button className="btn mt-2 mb-2 btn-primary-custom text-center" onClick={handleLogin}>Login</button> */}
            
              
              
              {/*  <div className="col-12 col-md-9">
                <form action="">
                  <div className="form-group">
                    <label htmlFor="username" className="form-label">
                      {TT(LabelKeys.HEADER_LOGIN, locale)}
                    </label>
                    <input
                      type="text"
                      id="username"
                      className="form-control"
                      placeholder="Email Address"
                    />
                  </div>
                  <div className="form-group d-flex flex-column flex-md-row">
                    <input
                      type="password"
                      id="password"
                      className="form-control mr-2"
                      placeholder="Password"
                    />
                    <button className="btn btn-login">
                      <div className="btn-text">
                        <span> {TT(LabelKeys.HEADER_LOGIN, locale)}</span>
                      </div>
                      <i className="fal fa-arrow-circle-right"></i>
                   </button>
                  </div>
                </form>
              </div>
              <div className="col-12 col-md-3">
                <div className="register-nav">
                  <a href="#" className="btn btn-light">
                    <div className="btn-text">
                      <span> {TT(LabelKeys.HEADER_REGISTER, locale)}</span>
                      <small>
                        {TT(LabelKeys.HEADER_REGISTER_SUB_TEXT, locale)}
                      </small>
                    </div>
                    <i className="fal fa-arrow-circle-right"></i>
                  </a>
                  <a href="#" className="btn btn-light">
                    <div className="btn-text">
                      <span>
                        {TT(LabelKeys.HEADER_FORGOT_PASSWORD, locale)}
                      </span>
                    </div>
                    <i className="fal fa-arrow-circle-right ml-1"></i>
                  </a>
                </div>
              </div>*/}
           
      <CategoryDropDownMobile categoryData={categoryData}>
        {TT(LabelKeys.HEADER_PRODUCTS, locale)}
      </CategoryDropDownMobile>
    </div>
  );
};

export default Header;
