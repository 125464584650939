import germanTranslation from "./de/translation";
import { LabelKeys } from "./label-config";

export const TT = (text, locale) => {
  switch (locale) {
    case "de":
      return germanTranslation[text] ? germanTranslation[text] : text;
    case "en":
      return text;
    default:
      return text;
  }
};
export { LabelKeys };
