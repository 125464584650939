import React from 'react';
import { useHistory } from "react-router-dom";
import { useLocaleContext } from "../../context/localeContext";
import { LabelKeys, TT } from "../../config/locale";

import Hooks from "../../hooks";

const AccountSettings = () => {
    const history = useHistory()
    const location = window.location.pathname + window.location.search;
    const { locale } = useLocaleContext();
    const { storefrontWebAuth, isSignedIn } = Hooks.useAuth()
    const shopperProfile = storefrontWebAuth.getProfile() || {}

    const handleLogout = () => {
        // Workaround until we create a connection in Cimpress tenant
        // Later this can be changed to `storefrontWebAuth.signOut()`
        storefrontWebAuth.signOut({
            domain: 'wirmachendruck-shoppers-dev.eu.auth0.com',
            client_id: 't9o1vkgpwcbPP6k622h4adFVqXNOX6Tt',
            redirectUri: location.pathname + location.search,
        })
    }

    const handleLogin = () => {
        history.push({pathname: "/login", state: {userPath: location }})
      }

    return <div
            className="header-box collapse fade"
            id="login-box"
            data-parent="#mainHeader"
            >
                 <div className="header-box-body">
                    <div className="container" style={{display: 'block', float: 'right'}}>
                        
                            <div className="account-nav" style={{ minWidth: '150px' }}>
                                <div className="account-nav-body" id='account-nav-details' style={{color: '$gray'}} >
                                    { isSignedIn
                                        ? <>   
                                            <li>
                                                <a data-toggle="collapse" data-target="#shopper-profile" data-parent="#shopper-profile" >
                                                    <span>{TT(LabelKeys.HEADER_PROFILE, locale)}</span>
                                                    <i className="fal fa-arrow-right" style={{float: "right", marginTop: '3px'}} />
                                                </a>
                                            </li>
                                            
                                            <div id='shopper-profile' className="collapse fade" style={{ marginBottom: '10px' }}>
                                                <div className="card card-body">
                                                    <div>
                                                        <span><b>{TT(LabelKeys.HEADER_EMAIL, locale)}</b> {shopperProfile.email}</span>
                                                    </div>
                                                    <div>
                                                        <span><b>{TT(LabelKeys.HEADER_NAME, locale)}</b> {shopperProfile.name}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <li>
                                                <a onClick={handleLogout} data-toggle="collapse" data-target="#login-box" style={{cursor: 'pointer'}}>
                                                    <span>{TT(LabelKeys.HEADER_LOGOUT, locale)}</span>
                                                    <i className="fal fa-arrow-right" style={{float: "right", marginTop: '3px'}} />
                                                </a>
                                            </li>
                                        </>
                                        : <li>
                                            <a onClick={handleLogin} data-toggle="collapse" data-target="#login-box" style={{cursor: 'pointer'}}>
                                                <span>{TT(LabelKeys.HEADER_LOGIN_REGISTER, locale)}</span>
                                                <i className="fal fa-arrow-right" style={{float: "right", marginTop: '3px'}} />
                                            </a>
                                        </li> 
                                    }
                                </div>
                            
                        </div>
                    </div>
                </div>
            </div>
}

export default AccountSettings