import _get from "lodash.get";
import { useEffect, useState } from "react";
import ApiUtils from "../utils/api-utils";

const useHighlightedCarouselCard = (locale) => {
  const [highlightedCarouselCard, setHighlightedCarouselCard] = useState();
  const [
    showHighlightedCarouselCard,
    setShowHighlightedCarouselCard,
  ] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        const results = await ApiUtils.doQuery(
          ApiUtils.queries.highlightedCarouselCard(locale),
          ""
        );
        if (_get(results, "data")) {
          setHighlightedCarouselCard(
            _get(results, "data.homePage.highlightedCarouselCardChoose", [])
          );
          setShowHighlightedCarouselCard(
            _get(
              results,
              "data.homePage.highlightedCarouselCardShowOnHomePage",
              false
            )
          );
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [locale]);
  return { highlightedCarouselCard, showHighlightedCarouselCard };
};

export default useHighlightedCarouselCard;
