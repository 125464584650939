export const limitWord = (str, n) => {
  return str.length > n ? `${str.substr(0, n - 1)}...` : str;
};
export const getLanguage = () => {
  const lang = localStorage.getItem("webshop-locale");
  return lang || "de";
};
export const setLanguage = (lang) => {
  localStorage.setItem("webshop-locale", lang);
};
