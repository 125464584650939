import _get from "lodash.get";
import { useEffect, useState } from "react";
import ApiUtils from "../utils/api-utils";

const usePrivacyPolicy = (locale) => {
  const [isLoading, setIsLoading] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState();
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const results = await ApiUtils.doQuery(
          ApiUtils.queries.privacyPolicy(locale),
          ""
        );
        if (_get(results, "data")) {
          setPrivacyPolicy(_get(results, "data.impressum", {}));
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [locale]);
  return { privacyPolicy, setPrivacyPolicy, isLoading };
};

export default usePrivacyPolicy;
