import React from "react";
import { Link } from "react-router-dom";

const ProductMenu = ({ products }) => {
  return products
    ? products.map((product) => {
        return (
          <li className="product-menu">
            <Link to={`/detail/${product.id}`} title={product.name}>
              {product.name}
            </Link>
          </li>
        );
      })
    : null;
};

export default ProductMenu;
