import _get from "lodash.get";
import { useEffect, useState } from "react";
import ApiUtils from "../utils/api-utils";

const useSingleImageCard = (locale) => {
  const [singleImageCard, setSingleImageCard] = useState();
  useEffect(() => {
    (async () => {
      try {
        const results = await ApiUtils.doQuery(
          ApiUtils.queries.singleImageCard(locale),
          ""
        );
        if (_get(results, "data")) {
          setSingleImageCard(_get(results, "data.homePage", {}));
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [locale]);
  return { singleImageCard };
};

export default useSingleImageCard;
