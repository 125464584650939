const URI = {
  RULESET: "https://ruleset.storefront.cimpress.io/v0/ruleset/",
  TRANSLATED_ATTR:
    "https://cim-commerce.st.cimpress.io/products/v0/accounts/111/products/",
  PRICE_INFO: (storefrontId) =>
    `https://core.st.cimpress.io/ecommerce/v0/storefronts/${storefrontId}/price`,
  PLACE_CART: (storefrontId) =>
    `https://core.st.cimpress.io/ecommerce/v0/storefronts/${storefrontId}/carts`,
  UPDATE_CART: (storefrontId, cartId) =>
    `https://core.st.cimpress.io/ecommerce/v0/storefronts/${storefrontId}/carts/${cartId}`,
  PLACE_ORDER: (storefrontId) =>
    `https://core.st.cimpress.io/ecommerce/v0/storefronts/${storefrontId}/orders`,
  GET_PAYMENT_DETAILS: (storefrontId, orderId) =>
    `https://core.st.cimpress.io/ecommerce/v0/storefronts/${storefrontId}/orders/${orderId}/payments`,
  VERIFY_PAYMENT: (storefrontId, orderId, paymentId) =>
    `https://core.st.cimpress.io/ecommerce/v0/storefronts/${storefrontId}/orders/${orderId}/payments/${paymentId}`,
};

const generateHeader = (body, method) => {
  const modified_header = {
    method,
    body: body ? JSON.stringify(body) : null,
  };
  return modified_header;
};

export default { URI, generateHeader };
