/* eslint-disable */
import _get from "lodash.get";
import { useEffect, useState } from "react";
import ApiUtils from "../utils/api-utils";

const useFooter = (locale) => {
  const [isLoading, setIsLoading] = useState(false);
  const [footerContact, setFooterContact] = useState();
  const [footerSocialMedia, setFooterSocialMedia] = useState();
  const [logoUrl, setLogoUrl] = useState("");

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const results = await ApiUtils.doQuery(
          ApiUtils.queries.footer(locale),
          ""
        );
        if (_get(results, "data")) {
          setFooterContact(_get(results, "data.footer.contactBlock", {}));
          setFooterSocialMedia(
            _get(results, "data.footer.socialMediaBlock", [])
          );
          setLogoUrl(_get(results, "data.logoTheme.uploadLogo.url", ""));
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [locale]);
  return { footerContact, footerSocialMedia, isLoading, logoUrl };
};

export default useFooter;
