import _get from "lodash.get";
import { useEffect, useState } from "react";
import ApiUtils from "../utils/api-utils";

const useHomePage = (locale) => {
  const [products, setProducts] = useState();
  useEffect(() => {
    (async () => {
      try {
        const results = await ApiUtils.doQuery(
          ApiUtils.queries.homeProductCard(locale),
          ""
        );
        if (_get(results, "data")) {
          setProducts(_get(results, "data.homePage.productCard", []));
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [locale]);
  return { products };
};

export default useHomePage;
