import React from "react";
import ProductMenu from "./product-menu";
import SubCategoryMenu from "./sub-category";

const CategoryDropDown = ({ categoryData = {}, children }) => {
  return (
    <li className="main-menu drop-down-menu">
      {children}
      <ul className="sub-menu">
        <SubCategoryMenu subCategories={categoryData.subCategory} />
        <ProductMenu products={categoryData.products} />

        
      </ul>
    </li>
  );
};

export default CategoryDropDown;
