import React, { createContext, useState } from 'react'
import StorefrontAuth from "@storefront/auth-js"

const AuthContext = createContext()

export const AuthProvider = (props) => {

    const [isSignedIn, setIsSignedIn] = useState(props.storefrontWebAuth.isSignedIn())
    const contextValue = {
        storefrontWebAuth: props.storefrontWebAuth,
        authConstants: StorefrontAuth.Constants,
        isSignedIn: isSignedIn,
        handleIsSignedIn: () => {
            setIsSignedIn(props.storefrontWebAuth.isSignedIn())
        }
    }

    return (
        <AuthContext.Provider value={contextValue}>
            {props.children}
        </AuthContext.Provider>
    )
}

const useAuth = () => {
    const context = React.useContext(AuthContext)
    if (context === undefined) {
        throw new Error('useAuth must be used within AuthProvider')
    }
    return context
}

export default useAuth;