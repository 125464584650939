/* eslint-disable  */
import { Snackbar } from "@cimpress/react-components";
import AttributeSelector, {
  ProductState,
} from "@storefront/attribute-selector";
import React, { Component } from "react";
import { LocaleContext, LOCALE_VALUES } from "../context/localeContext";
import "../styles/vendor/mcp-ux/mcp-ux-css.min.scss";
import client from "../utils/api-utils";

const p = (msg, obj = undefined) => {
  if (obj) console.log("[ATTR-SELCTOR]", msg, obj);
  else console.log("[ATTR-SELCTOR]", msg);
};
const URI = {
  RULESET: "https://ruleset.storefront.cimpress.io/v0/ruleset/",
  TRANSLATED_ATTR: (businessAccountId, referenceId) =>
    `https://cim-commerce.st.cimpress.io/products/v0/accounts/${businessAccountId}/products/${referenceId}/attributes`,

  PRICE_INFO: "https://core.st.cimpress.io/ecommerce/v0/price",
  PLACE_CART: (storefrontId) =>
    `https://core.st.cimpress.io/ecommerce/v0/storefronts/${storefrontId}/carts`,
  PLACE_ORDER: (storefrontId) =>
    `https://core.st.cimpress.io/ecommerce/v0/storefronts/${storefrontId}/order`,
};

const getAttributesByReferenceId = `query MyQuery($id: String!) {
  product(filter: {referenceId: {eq: $id}}) {
    sku
    attributeList {
      name
      slug
      sortOrder
      visible
      description
      attributeValueType
      key
    }
    referenceId
  }
}
`;

/* 
#####################################################################################################
PRE-REQUISITES FOR ATTRIBUTE SELECTOR

1) Visit https://artifactory.cimpress.io/artifactory/webapp/#/profile
2) Go to Edit Profile -> Generate API Token
3) Run $(npm login --registry= https://artifactory.cimpress.io/artifactory/api/npm/npm-release-local/)
      Username: Email ID without the domain [If Email: abc.xyz@cimpress.com then Username: abc.xyz]
      Password: Provide the API token which you had generated here
      Email: Your Cimpress corporate email address
4) Run $(npm i @cimpress-technology/attribute-selector)

#####################################################################################################
 */

export default class Selector extends Component {
  static contextType = LocaleContext;
  state = {
    locale: LOCALE_VALUES.english,
    isSelectorReady: false,
    translationMap: undefined,
    prodRuleSet: undefined,
    valueTranslationMap: undefined,
    snackBar: {
      isShow: false,
      message: undefined,
      type: "success",
    },

    /**
     * Below states maintained as provided onChange() method
     */
    resolvedAttributes: undefined,
    userSelection: {},
    variableAttributes: {},
    finalSelection: undefined,
    configurationURL: undefined,
    priceValue: undefined, // { currency, amt }
  };

  fetchRuleSet = async (skuId) => {
    let ruleSetURL = URI.RULESET + skuId;
    let result = await fetch(ruleSetURL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((responseJson) => {
      return responseJson.json();
    });
    return result[0];
  };

  fetchCMSAttributes = async (referenceId, locale) => {
    let result = await client.doQuery(
      client.queries.getAttributesByReferenceId(locale),
      {
        id: referenceId,
      }
    );
    return result.data.product;
  };

  fetchTranslatedAttributes = async (referenceId) => {
    const accountId =
      this.props.productDetails && this.props.productDetails.businessAccountId;
    if (!accountId) {
      p(
        "businessAccountId is not available for fetching translated attributes api"
      );
      return;
    }
    let result = await fetch(URI.TRANSLATED_ATTR(accountId, referenceId))
      .then((res) => res.json())
      .catch((err) => null);
    return result;
  };

  // fetchTranslatedAttributesMock = async () => {
  //   return MOCK_GET_ATTRIBUTE_BY_PRODUCT_ID;
  // };

  fetchAllData = async () => {
    p("SKU ID", this.props.productDetails.sku);
    p("Reference ID", this.props.productDetails.referenceId);
    this.setState({
      isSelectorReady: false,
    });
    const locale = this.context.locale || LOCALE_VALUES.english;
    const prodRuleSet = await this.fetchRuleSet(this.props.productDetails.sku);
    const { attributeList } = await this.fetchCMSAttributes(
      this.props.productDetails.referenceId,
      locale
    );

    const translatedAttrList = await this.fetchTranslatedAttributes(
      this.props.productDetails.referenceId
    );
    const translationMap = {};
    const valueTranslationMap = {};

    /**
     * Generating translationMap data
     */
    attributeList &&
      attributeList.forEach((element) => {
        if (!element.name) return;
        let attributeKey = element.key.replace(
          this.props.productDetails.referenceId + "_",
          ""
        );
        translationMap[attributeKey] = element.name;
      });

    /**
     * Generating valueTranslationMap Data
     */
    translatedAttrList &&
      translatedAttrList
        .filter((item) => item.attributeValueType === "listOfValues")
        .forEach((item) =>
          item.value.forEach((valueItem) => {
            switch (locale) {
              case LOCALE_VALUES.english:
                if (valueItem.label["en"]) {
                  valueTranslationMap[`${item.mcpKey}_${valueItem.key}`] =
                    valueItem.label["en"];
                }
                break;
              case LOCALE_VALUES.german:
                if (valueItem.label["de-DE"]) {
                  valueTranslationMap[`${item.mcpKey}_${valueItem.key}`] =
                    valueItem.label["de-DE"];
                }
              default:
            }
          })
        );

    this.props.onTranslate &&
      this.props.onTranslate({ translationMap, valueTranslationMap });

    this.setState({
      prodRuleSet,
      translationMap,
      valueTranslationMap,
      isSelectorReady: true,
    });
  };

  setLocale = () => {
    const locale = this.context.locale || LOCALE_VALUES.english;
    if (locale !== this.state.locale) {
      this.setState({ locale });
      return true;
    }
    return false;
  };
  componentDidMount() {
    let { referenceId } = this.props.productDetails;
    this.setLocale();
    if (referenceId) {
      this.fetchAllData().catch(() => {});
    }
  }

  componentDidUpdate(prevProps) {
    let { referenceId: prevReferenceId } = prevProps.productDetails;
    let { referenceId } = this.props.productDetails;
    if (this.setLocale() || prevReferenceId !== referenceId) {
      this.fetchAllData().catch(() => {});
    }
  }

  onChangeCockpit = (changeResponse) => {
    let onChangeResponse = changeResponse;

    if (
      this.selector &&
      this.selector.state &&
      this.selector.state.attributeModelExplorer
    ) {
      // passing the user selection
      onChangeResponse.userSelection = this.selector.state.attributeModelExplorer.getSelectedValues();

      // passing selected attributes
      let selectionOptions = this.getSelectionAttributes(
        this.selector.state.attributeModelExplorer
      );

      let selectedOptions = selectionOptions.reduce((map, option) => {
        if (!option.assignedValues) {
          map[option.key] = option.getResolvedValue();
        }
        return map;
      }, {});

      onChangeResponse.selectedAttributes = JSON.parse(
        JSON.stringify(selectedOptions, null, 2)
      );

      // passing the Attribute Model Explorer instance, some useful functions can be leveraged.
      // if(this.props.passInstance)
      onChangeResponse.attributeModelExplorerInstance = this.selector.state.attributeModelExplorer;
    }
    if (changeResponse.state === ProductState.fullyResolved) {
      const resolvedAttributes = [];
      let finalSelectionArr = {};

      changeResponse.attributes.forEach((attr) => {
        if (attr.resolvedValue !== undefined) {
          finalSelectionArr[attr.key] = attr.resolvedValue;
        }

        if (attr.isDisplayed) {
          resolvedAttributes.push({
            attributeKey: attr.key,
            attributeValue: attr.resolvedValue,
          });
        }
      });

      onChangeResponse.finalSelection = finalSelectionArr;
      onChangeResponse.resolvedAttributes = resolvedAttributes;
      this.propsOnChange(onChangeResponse);

      /**
       * Commenting since we do not require config url as of now
       */
      // this.selector &&
      //   this.selector
      //     .getConfigurationUrl()
      //     .then((configurationURL) => {
      //       if (configurationURL === null) {
      //         console.log("Configuration URL is not found.");
      //         this.propsOnChange(onChangeResponse);
      //       } else {
      //         onChangeResponse.configurationURL = configurationURL;
      //         this.propsOnChange(onChangeResponse);
      //       }
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //       this.propsOnChange(onChangeResponse);
      //     });
    } else {
      this.propsOnChange(onChangeResponse);
    }
  };

  propsOnChange = (onChangeResponse) => {
    const {
      resolvedAttributes,
      userSelection,
      selectedAttributes,
      finalSelection,
      configurationURL,
    } = onChangeResponse;

    // console.log("Props Change");
    // console.log("resolvedAttributes", resolvedAttributes);
    // console.log("userSelection", userSelection);
    // console.log("selectedAttributes", selectedAttributes);
    // console.log("finalSelection", finalSelection);
    // console.log("configurationURL", configurationURL);

    // if (resolvedAttributes) this.fetchPriceInfo(resolvedAttributes);

    this.setState({
      resolvedAttributes,
      userSelection,
      variableAttributes: selectedAttributes,
      finalSelection,
      configurationURL,
      priceValue: resolvedAttributes ? this.state.priceValue : undefined,
    });

    this.props.onChange(onChangeResponse);
  };

  getSelectionAttributes = (attributeModelExplorer) => {
    const selectableAttributes = attributeModelExplorer
      .getAttributes()
      .filter((a) => a.metadata.get("isDisplayed"));
    const selectablePivotAttributes = attributeModelExplorer
      .getPivotAttributes()
      .filter((a) => a.metadata.get("isDisplayed"));
    return [...selectableAttributes, ...selectablePivotAttributes];
  };

  // printUrlData = (url) => {
  //   fetch(url, getAuthorizationHeader())
  //     .then((res) => res.json())
  //     .then((res) => console.log("URL Data", res))
  //     .catch((err) => console.log("URL Data Err", err));
  // };

  hideSnackbar = () => {
    this.setState({
      snackBar: { ...this.state.snackBar, isShow: false },
    });
  };

  showSnackBar = (message, type = "success") => {
    this.setState({
      snackBar: {
        isShow: true,
        message: message,
        type: type,
      },
    });
  };

  handleAttrSubmit = () => {
    this.postPlaceOrder();
  };

  render() {
    return (
      <>
        {!this.state.isSelectorReady && (
          <h2>Loading selectors.. Please wait..</h2>
        )}
        {this.state.isSelectorReady && (
          <div className="use-mcp-style-attr-selector use-mcp-style">
            <AttributeSelector
              ref={(instance) => {
                this.selector = instance;
              }}
              // authToken={auth.getAccessToken()}
              ruleSet={this.state.prodRuleSet}
              onChange={this.onChangeCockpit}
              translationMap={this.state.translationMap}
              valueTranslationMap={this.state.valueTranslationMap}
            />
            {/*
      <h2>
        Price:{" "}
        <b>
          &euro;{" "}
          {this.state.priceValue
            ? Number(this.state.priceValue.amt) / 100
            : 0}
        </b>
      </h2>
      <h2>
        Tax Rate (%):{" "}
        <b>
          {" "}
          {this.state.priceValue
            ? Number(this.state.priceValue.taxRate)
            : 0}
        </b>
      </h2>
      <h2>
        Checkout Price:{" "}
        <b>
          &euro;{" "}
          {this.state.priceValue
            ? Number(this.state.priceValue.totalAmt) / 100
            : 0}
        </b>
      </h2>
      <br />
      <Button
        type="primary"
        onClick={this.handleAttrSubmit}
        disabled={!this.state.priceValue}
      >
        Place Order
      </Button>
      */}
          </div>
        )}
        {/* To Show Snackbar */}
        <Snackbar
          show={this.state.snackBar.isShow}
          bsStyle={this.state.snackBar.type}
          delay={3000}
          onHideSnackbar={this.hideSnackbar}
        >
          {this.state.snackBar.message}
        </Snackbar>
      </>
    );
  }
}
