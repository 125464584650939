import React, { useContext, useState } from "react";

const LOCALE_VALUES = {
  english: "en",
  german: "de",
};

const LocaleContext = React.createContext({});
// const LocaleProvider = ({ children }) => {
//   const [locale, setLocale] = useState("en");
//   return (
//     <LocaleContext.Provider value={{ locale, setLocale }}>
//       {children}
//     </LocaleContext.Provider>
//   );
// };
const LocaleProviderInit = ({ children, locale }) => {
  const [state, setState] = useState(locale);
  return (
    <LocaleContext.Provider value={{ locale: state, setLocale: setState }}>
      {children}
    </LocaleContext.Provider>
  );
};
const useLocaleContext = () => {
  const { locale, setLocale } = useContext(LocaleContext);
  return { locale, setLocale };
};

export { LocaleContext, useLocaleContext, LocaleProviderInit, LOCALE_VALUES };
