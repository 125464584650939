import React, { useReducer, useEffect, createContext } from "react";

let reducer = (info, newInfo) => {
  if (newInfo === null) {
    localStorage.removeItem("cart");
    return initialState;
  }
  return { ...info, ...newInfo };
};

const initialState = {
  lineItems: [],
};
const localState = JSON.parse(localStorage.getItem("cart"));

export const CartContext = createContext();

export const CartProvider = (props) => {
  //const [cart, setCart] = useState([]);
  const [cart, setCart] = useReducer(reducer, localState || initialState);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);
  return <CartContext.Provider value={[cart, setCart]} {...props} />;
};
