import React, { useCallback, useRef, useState } from "react";
import ProductMenu from "./product-menu";

const SubCategoryMenu = ({ subCategories }) => {
  const [isShown, setIsShown] = useState(
    Array(subCategories ? subCategories.length : 0).fill(false)
  );
  //const handleShowTrue = useCallback(() => setIsShown(true));
  //const handleShowFalse = useCallback(() => setIsShown(false));
  const handleMenuClick = (i, v) => {
    setIsShown(Object.assign([...isShown], { [i]: v }));
  };

  return subCategories
    ? subCategories.map((item, index) => {
        return (
          <li
            key={"sub-menu-mobile" + item.name}
            //onMouseEnter={handleShowTrue}
            //onMouseLeave={handleShowFalse}

            className={!isShown[index] ? "mobile-subover-visible" : ""}
          >
            <a
              title={item.name}
              onClick={() => handleMenuClick(index, !isShown[index])}
              className={
                isShown[index]
                  ? "mobile-subover-header mobile-back"
                  : "mobile-dropdown"
              }
            >
              {item.name}
            </a>
            <ul
              className={
                isShown[index]
                  ? "mobile-submenu mobile-subview"
                  : "mobile-submenu"
              }
            >
              {/*SubCategoryMenu({ subCategories: item.subCategory })*/}
              <ProductMenu products={item.products} />
            </ul>
          </li>
        );
      })
    : null;
};

export default SubCategoryMenu;
