import React from "react";
import { useLocaleContext } from "../context/localeContext";
import { GenericUtil } from "../utils";

const LocaleSelect = () => {
  const { locale, setLocale } = useLocaleContext();
  const handleLocaleSelect = (selectedLocale) =>{
    GenericUtil.setLanguage(selectedLocale);
    setLocale(selectedLocale)
  }

  return (
      <ul className='locale-drop-down-menu'>
        <li
          //onMouseEnter={handleShowTrue}
          //onMouseLeave={handleShowFalse}
          onClick = {() => handleLocaleSelect('en')}
          style= {locale === 'en' ? {backgroundColor: '#bfbfbf'}: {} }
        >
          <a
            title='EN'
            style={{
              width: "max-content",
              maxWidth: "300px",
              whiteSpace: "pre-wrap",
              backgroundColor: "inherit",
            }}
          >
            <b>en - English</b>
          </a>
        </li>
        <li
          //onMouseEnter={handleShowTrue}
          //onMouseLeave={handleShowFalse}
          onClick = {() => handleLocaleSelect('de')}
          style= {locale === 'de' ? {backgroundColor: '#bfbfbf'}: {} }
        >
          <a
            title='EN'
            style={{
              width: "max-content",
              maxWidth: "300px",
              whiteSpace: "pre-wrap",
              backgroundColor: "inherit",
            }}
            
          >
            <b>de - German</b>
          </a>
        </li>
      </ul>
    
  );
};

export default LocaleSelect;
