import _get from "lodash.get";
import { useEffect, useState } from "react";
import ApiUtils from "../utils/api-utils";

const useTeaser = (locale) => {
  const [teasers, setTeasers] = useState();
  useEffect(() => {
    (async () => {
      try {
        const results = await ApiUtils.doQuery(
          ApiUtils.queries.homePageTeaser(locale),
          ""
        );
        if (_get(results, "data")) {
          setTeasers(_get(results, "data.homePage.homePageTeaser", []));
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [locale]);
  return { teasers };
};

export default useTeaser;
