import _get from "lodash.get";
import React from "react";
import { CartContext } from "../context/cartContext";
import OrderUtil from "../utils/order-utils";
import paypalImage from "../images/paypal.png";
import Hooks from "../hooks";
import { LabelKeys } from "../config/locale/label-config";
import { TT } from "../config/locale";
import { useLocaleContext } from "../context/localeContext";

const PAYMENTS = {
  PAYPAL: "paypal",
  ONACCOUNT: "onaccount",
};
class Cart extends React.Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      address: {
        company: "",
        firstName: "Prateek",
        lastName: "",
        streetName: "",
        additionalStreetInfo: "",
        postalCode: "",
        city: "",
        // state: "",
        country: "DE",
        phone: "",
        email: "",
      },
      invoiceAddress: {
        company: "",
        firstName: "",
        lastName: "",
        streetName: "",
        additionalStreetInfo: "",
        postalCode: "",
        city: "",
        // state: "",
        country: "DE",
        phone: "",
        email: "",
      },
      errors: {
        company: "",
        firstName: "",
        lastName: "",
        streetName: "",
        postalCode: "",
        city: "",
        country: "",
        phone: "",
        email: "",
      },
      invoiceErrors: {
        company: "",
        firstName: "",
        lastName: "",
        streetName: "",
        postalCode: "",
        city: "",
        country: "",
        phone: "",
        email: "",
      },
      selectedCountry: undefined,
      isAddressIdentical: true,
      selectedPaymentMethod: PAYMENTS.PAYPAL,
      isCheckOutLoading: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.checkFieldRequired = this.checkFieldRequired.bind(this);
  }

  handleInputChange = (event) => {
    let updatedData = { ...this.state.address };
    const { name, value } = event.target;
    updatedData[name] = value;
    let errors = this.state.errors;
    switch (name) {
      case "firstName":
      case "lastName":
      case "streetName":
      case "postalCode":
      case "city":
      case "country":
      case "phone":
      case "email":
        errors[name] = value.length < 1 ? "Please fill the required field" : "";
        break;

      default:
        break;
    }
    this.setState({ errors, address: updatedData });
  };
  handleInvoiceChange = (event) => {
    let updatedData = { ...this.state.invoiceAddress };
    const { name, value } = event.target;
    updatedData[name] = value;
    let invoiceErrors = this.state.invoiceErrors;
    switch (name) {
      case "firstName":
      case "lastName":
      case "streetName":
      case "postalCode":
      case "city":
      case "country":
      case "phone":
      case "email":
        invoiceErrors[name] =
          value.length < 1 ? "Please fill the required field" : "";
        break;

      default:
        break;
    }
    this.setState({ invoiceAddress: updatedData, invoiceErrors });
  };
  updateError = () => {
    let { errors, address } = this.state;

    Object.keys(address).map((name) => {
      switch (name) {
        case "firstName":
        case "lastName":
        case "streetName":
        case "postalCode":
        case "city":
        case "country":
        case "phone":
        case "email":
          errors[name] =
            address[name].length < 1 ? "Please fill the required field" : "";
          break;

        default:
          break;
      }
    });
    this.setState({ errors });
  };

  updateInvoiceError = () => {
    let { invoiceErrors, invoiceAddress } = this.state;

    Object.keys(invoiceAddress).map((name) => {
      switch (name) {
        case "firstName":
        case "lastName":
        case "streetName":
        case "postalCode":
        case "city":
        case "country":
        case "phone":
        case "email":
          invoiceErrors[name] =
            invoiceAddress[name].length < 1
              ? "Please fill the required field"
              : "";
          break;

        default:
          break;
      }
    });
    this.setState({ invoiceErrors });
  };
  validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
    return valid;
    /*let { address } = this.state;
    let valid = true;
    Object.keys(errors).forEach((val) => {
      address[val] && address[val].length < 1 && (valid = false);
    });
    return valid;
    */
  };

  handleCountryChange = (event) => {
    let address = { ...this.state.address, country: event.target.value };
    this.setState({
      selectedCountry: event.target.value,
      address,
    });
  };

  checkFieldRequired = () => {
    const { address } = this.state;

    this.updateError();
    if (
      _get(address, "firstName.length") === 0 ||
      _get(address, "lastName.length") === 0 ||
      _get(address, "streetName.length") === 0 ||
      _get(address, "postalCode.length") === 0 ||
      _get(address, "city.length") === 0 ||
      //_get(address, "state.length") === 0 ||
      _get(address, "country.length") === 0 ||
      _get(address, "phone.length") === 0 ||
      _get(address, "email.length") === 0
      // !selectedCountry
    ) {
      return true;
    }
    return false;
  };
  checkInvoiceFieldRequired = () => {
    const { invoiceAddress, isAddressIdentical } = this.state;
    this.updateInvoiceError();
    if (!isAddressIdentical)
      if (
        _get(invoiceAddress, "firstName.length") === 0 ||
        _get(invoiceAddress, "lastName.length") === 0 ||
        _get(invoiceAddress, "streetName.length") === 0 ||
        _get(invoiceAddress, "postalCode.length") === 0 ||
        _get(invoiceAddress, "city.length") === 0 ||
        _get(invoiceAddress, "country.length") === 0 ||
        _get(invoiceAddress, "phone.length") === 0 ||
        _get(invoiceAddress, "email.length") === 0
      ) {
        return true;
      }
    return false;
  };
  getPaymentDetails = async (order) => {
    const { selectedPaymentMethod } = this.state;
    let storefrontId = localStorage.getItem("storefrontId");
    let requestBody = {};
    if (selectedPaymentMethod === PAYMENTS.PAYPAL) {
      requestBody = {
        method: "paypal",
      };
    } else {
      requestBody = {
        method: "ON_ACCOUNT",
        version: order.version,
      };
    }

    return fetch(
      OrderUtil.URI.GET_PAYMENT_DETAILS(storefrontId, order.id),
      OrderUtil.generateHeader(requestBody, "POST")
    )
      .then((res) => {
        if (!res.ok) {
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        sessionStorage.setItem("payment", JSON.stringify(res));
        if (selectedPaymentMethod === PAYMENTS.ONACCOUNT) {
          this.props.history.push(`/checkout/success`);
        }
        return res.paypalOrderID;
      })
      .catch((err) => {
        alert("Error while placing Order");
      });
  };

  postOrderApi = async (cart) => {
    let storefrontId = localStorage.getItem("storefrontId");

    let requestBody = {
      version: cart.version,
      id: cart.id,
    };

    return fetch(
      OrderUtil.URI.PLACE_ORDER(storefrontId),
      OrderUtil.generateHeader(requestBody, "POST")
    )
      .then((res) => {
        if (!res.ok) {
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        sessionStorage.setItem("order", JSON.stringify(res));
        return res;
      })
      .catch((err) => {
        alert("Error while placing Order");
      });
  };

  fetchCart = async (cartId) => {
    let storefrontId = localStorage.getItem("storefrontId");
    return await fetch(OrderUtil.URI.UPDATE_CART(storefrontId, cartId), {
      method: "GET",
    })
      .then((res) => {
        if (!res.ok) {
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error("Error while fetching Cart");
      });
  };

  updateAddress = async (cart) => {
    const { address, invoiceAddress, isAddressIdentical } = this.state;
    const invoiceAdd = isAddressIdentical ? address : invoiceAddress;
    let storefrontId = localStorage.getItem("storefrontId");
    let requestBody = {
      version: cart.version,
      actions: [
        {
          action: "setShippingAddress",
          address: address,
        },
        {
          action: "setBillingAddress",
          address: invoiceAdd,
        },
      ],
    };

    return fetch(
      OrderUtil.URI.UPDATE_CART(storefrontId, cart.id),
      OrderUtil.generateHeader(requestBody, "POST")
    )
      .then((res) => {
        if (!res.ok) {
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        alert("Error while placing Order");
      });
  };

  placeOrder = async () => {
    let [globalCart, setGlobalCart] = this.context;

    if (this.checkFieldRequired()) {
      alert("Please fill all mandatory fields");
      return false;
    }
    this.setState({ isCheckOutLoading: true });
    let cartInfo = await this.fetchCart(_get(globalCart, "id"));
    let cart = await this.updateAddress(cartInfo);
    let order = await this.postOrderApi(cart);
    if (order) {
      setGlobalCart(null);
    }
    let paymentId = await this.getPaymentDetails(order);
    this.setState({ isCheckOutLoading: false });
    return paymentId;
  };

  handleCreateOrder = (data, action) => {
    return this.placeOrder();
  };

  handleApproveOrder = (data, action) => {
    let storefrontId = localStorage.getItem("storefrontId");
    let order = JSON.parse(sessionStorage.getItem("order"));
    let payment = JSON.parse(sessionStorage.getItem("payment"));

    let requestBody = {
      paypalOrderID: _get(payment, "paypalOrderID"),
      orderVersion: _get(payment, "orderVersion"),
      paymentVersion: _get(payment, "paymentVersion"),
      transactionID: _get(payment, "transactionID"),
    };

    this.props.history.push(`/checkout/processing`);
    fetch(
      OrderUtil.URI.VERIFY_PAYMENT(
        storefrontId,
        _get(order, "id"),
        _get(payment, "paymentId")
      ),
      OrderUtil.generateHeader(requestBody, "POST")
    )
      .then((res) => {
        if (!res.ok) {
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        // sessionStorage.setItem("payment", JSON.stringify(res));
        this.props.history.replace(`/checkout/success`);
        //return res.paypalOrderID;
      })
      .catch((err) => {
        alert("Error while registering payment");
      });
  };

  handlePaypalClick = (data, actions) => {
    let fieldMissing = this.checkFieldRequired();
    let invoiceFieldMissing = () => {
      if (this.state.isAddressIdentical) return false;
      return this.checkInvoiceFieldRequired();
    };
    if (!fieldMissing && !invoiceFieldMissing()) {
      return actions.resolve();
    } else {
      return actions.reject();
    }
  };
  handleRadioClick = (event) => {
    if (event.target.name === "identical")
      this.setState({ isAddressIdentical: true });
    else this.setState({ isAddressIdentical: false });
  };

  async componentWillMount() {
    if(!this.props.isSignedIn) 
      this.props.history.replace({
      pathname: "/login",
      state: {
        userPath: window.location.pathname + window.location.search 
      }});      
    else {
      let storefrontId = localStorage.getItem("storefrontId");
      let [globalCart, setGlobalCart] = this.context;
      let cartId = _get(globalCart, "id")
      let requestBody = {
        version: _get(globalCart, "version"),
        actions: [
          {
            action: "setCustomerEmail",
            email: this.props.customerEmail
          },
        ],
      };
      await fetch(
        OrderUtil.URI.UPDATE_CART(storefrontId, cartId),
        OrderUtil.generateHeader(requestBody, "POST")
      )
        .then((res) => {
          if (!res.ok) {
            throw Error(res.statusText);
          }
          return res.json();
        })
        .then((res) => {
          setGlobalCart(res);
          return res;
        })
        .catch((err) => {
          //this.showSnackBar("Error while placing Cart", "danger");
          alert("Error while adding email");
        });
    }
  }
  
  handlePaymentRadioClick = (event) => {
    this.setState({ selectedPaymentMethod: event.target.value });
  };

  async componentDidMount() {
    
    let [globalCart, setGlobalCart] = this.context;
    let cartInfo = await this.fetchCart(_get(globalCart, "id"));
    setGlobalCart(cartInfo);
    if (_get(cartInfo, "shippingAddress")) {
      this.setState({ address: _get(cartInfo, "shippingAddress", {}) });
    }
    try {
      window.paypal
        .Buttons({
          style: {
            layout: "vertical",
            shape: "rect",
            label: "checkout",
          },
          funding: {
            disallowed: [window.paypal.FUNDING.CARD],
          },
          branding: "false",
          // onInit is called when the button first renders
          onInit: function (data, actions) {
            // Disable the buttons
            // actions.disable();
          },
          // onClick is called when the button is clicked
          onClick: this.handlePaypalClick.bind(this),
          // Call your server to set up the transaction
          createOrder: this.handleCreateOrder.bind(this),

          // Call your server to finalize the transaction
          onApprove: this.handleApproveOrder,
        })
        .render("#paypal-button-container");
    } catch (err) {
      console.log("err =============>", err);
    }

    return () => {
      //window.document.body.removeChild(paypalButtonScript);
    };
  }

  render() {
    
    let globalCart = this.context;
    let price = _get(globalCart[0], "taxedPrice");
    const {
      address,
      invoiceAddress,
      selectedCountry,
      isAddressIdentical,
      errors,
      invoiceErrors,
      selectedPaymentMethod,
      isCheckOutLoading,
    } = this.state;
    const locale = this.props.locale;
    document.title = TT(LabelKeys.TITLE_CART, locale);
    return (
      <>
        <div class="container mt-4 mb-5">
          <div class="address">
            <div class="row row-eq">
              <div class="col-12 col-md-5">
                <div class="page">
                  <div class="page-title">
                    <h5>{TT(LabelKeys.ADDRESS_SHIPPING, locale)}</h5>
                  </div>
                </div>
                <div class="invoice">
                  <form action="">
                    <div class="form-group">
                      <label
                        htmlFor="company"
                        class="form-label text-capitalize"
                      >
                        {TT(LabelKeys.ADDRESS_FIELD_COMPANY, locale)}
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        name="company"
                        id="company"
                        placeholder="Name of company"
                        onChange={this.handleInputChange}
                        value={_get(address, "company")}
                      />
                    </div>

                    <div class="form-group">
                      <label
                        htmlFor="firstName"
                        class="form-label text-capitalize"
                      >
                        {TT(LabelKeys.ADDRESS_FIELD_FIRST_NAME, locale)}{" "}
                        <sup>*</sup>
                      </label>
                      <input
                        type="text"
                        class={
                          errors.firstName.length > 0
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        name="firstName"
                        id="firstName"
                        onChange={this.handleInputChange}
                        value={_get(address, "firstName")}
                      />
                      {errors.firstName.length > 0 && (
                        <div class="invalid-feedback">{errors.firstName}</div>
                      )}
                    </div>

                    <div class="form-group">
                      <label
                        htmlFor="surname"
                        class="form-label text-capitalize"
                      >
                        {TT(LabelKeys.ADDRESS_FIELD_LAST_NAME, locale)}{" "}
                        <sup>*</sup>
                      </label>
                      <input
                        type="text"
                        class={
                          errors.lastName.length > 0
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        name="lastName"
                        id="lastName"
                        onChange={this.handleInputChange}
                        value={_get(address, "lastName")}
                      />
                      {errors.lastName.length > 0 && (
                        <div class="invalid-feedback">{errors.lastName}</div>
                      )}
                    </div>

                    <div class="form-group">
                      <label
                        htmlFor="street"
                        class="form-label text-capitalize"
                      >
                        {TT(LabelKeys.ADDRESS_FIELD_LINE_1, locale)}{" "}
                        <sup>*</sup>
                      </label>
                      <input
                        type="text"
                        class={
                          errors.streetName.length > 0
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        name="streetName"
                        id="streetName"
                        onChange={this.handleInputChange.bind(this)}
                        value={_get(address, "streetName")}
                      />
                      {errors.streetName.length > 0 && (
                        <div class="invalid-feedback">{errors.streetName}</div>
                      )}
                    </div>

                    <div class="form-group">
                      <label
                        htmlFor="additionalStreetInfo"
                        class="form-label text-capitalize"
                      >
                        {TT(LabelKeys.ADDRESS_FIELD_LINE_2, locale)}{" "}
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        name="additionalStreetInfo"
                        id="additionalStreetInfo"
                        onChange={this.handleInputChange.bind(this)}
                        value={_get(address, "additionalStreetInfo")}
                      />
                    </div>

                    <div class="form-group">
                      <label htmlFor="city" class="form-label text-capitalize">
                        {TT(LabelKeys.ADDRESS_FIELD_CITY, locale)}
                        <sup>*</sup>
                      </label>
                      <input
                        type="text"
                        class={
                          errors.city.length > 0
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        name="city"
                        id="city"
                        onChange={this.handleInputChange.bind(this)}
                        value={_get(address, "city")}
                      />
                      {errors.city.length > 0 && (
                        <div class="invalid-feedback">{errors.city}</div>
                      )}
                    </div>
                    {/*
                    <div class="form-group">
                      <label htmlFor="state" class="form-label text-capitalize">
                        State
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        name="state"
                        id="state"
                        onChange={this.handleInputChange.bind(this)}
                        value={_get(address, "state")}
                      />
                    </div>
                        */}
                    <div class="form-group">
                      <label
                        htmlFor="postalCode"
                        class="form-label text-capitalize"
                      >
                        {TT(LabelKeys.ADDRESS_FIELD_POSTAL_CODE, locale)}
                        <sup>*</sup>
                      </label>
                      <input
                        type="text"
                        class={
                          errors.postalCode.length > 0
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        name="postalCode"
                        id="postalCode"
                        onChange={this.handleInputChange.bind(this)}
                        value={_get(address, "postalCode")}
                      />
                      {errors.postalCode.length > 0 && (
                        <div class="invalid-feedback">{errors.postalCode}</div>
                      )}
                    </div>

                    <div class="form-group">
                      <label
                        htmlFor="country"
                        class="form-label text-capitalize"
                      >
                        {TT(LabelKeys.ADDRESS_FIELD_COUNTRY, locale)}{" "}
                        <sup>*</sup>
                      </label>
                      <select
                        className="form-control"
                        name="country"
                        id="country"
                        value={selectedCountry}
                        onChange={this.handleCountryChange.bind(this)}
                      >
                        <option value="DE">Germany</option>
                        {/*CountryList && CountryList.map((item) => {
                          return <option value={item.code}>{item.name}</option>;
                        })*/}
                      </select>
                    </div>

                    <div class="form-group">
                      <label htmlFor="phone" class="form-label text-capitalize">
                        {TT(LabelKeys.ADDRESS_FIELD_PHONE, locale)} <sup>*</sup>
                      </label>
                      <input
                        type="text"
                        class={
                          errors.phone.length > 0
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        name="phone"
                        id="phone"
                        onChange={this.handleInputChange.bind(this)}
                        value={_get(address, "phone")}
                      />
                      {errors.phone.length > 0 && (
                        <div class="invalid-feedback">{errors.phone}</div>
                      )}
                    </div>

                    <div class="form-group">
                      <label htmlFor="phone" class="form-label text-capitalize">
                        {TT(LabelKeys.ADDRESS_FIELD_EMAIL, locale)} <sup>*</sup>
                      </label>
                      <input
                        type="email"
                        class={
                          errors.email.length > 0
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        name="email"
                        id="email"
                        onChange={this.handleInputChange.bind(this)}
                        value={_get(address, "email")}
                      />
                      {errors.email.length > 0 && (
                        <div class="invalid-feedback">{errors.email}</div>
                      )}
                    </div>
                    {/*
                  <div class="form-group">
                    <label htmlFor="UstIdnr" class="form-label text-capitalize">
                      UstIdnr.
                    </label>
                    <input
                      type="email"
                      class="form-control is-invalid"
                      name="UstIdnr"
                      id="UstIdnr"
                    />
                    <div class="invalid-feedback">* required fields</div>
                  </div>
                  
                  <div class="d-inline-flex w-100 justify-content-end">
                    <button class="btn btn-primary" >
                      Update
                    </button>
                  </div>
                  */}
                  </form>
                </div>
              </div>
              <div class="col-12 col-md-5">
                <div class="page mt-3 mt-md-0">
                  <div class="page-title">
                    <h5>{TT(LabelKeys.ADDRESS_INVOICE, locale)}</h5>
                  </div>
                </div>
                {/* <div class="checkbox">
                  <input
                    type="checkbox"
                    id="shippingAddress"
                    name="shippingAddress"
                    checked={isAddressIdentical}
                  />
                  <label htmlFor="shippingAddress">
                    <span class="circle"></span>
                    Shipping address is identical with invoice address
                  </label>
                </div> */}
                <div>
                  <input
                    type="radio"
                    name="identical"
                    className="radio-margin"
                    checked={isAddressIdentical}
                    onChange={this.handleRadioClick.bind(this)}
                  />
                  <div className="radio-button-label">
                    {TT(LabelKeys.CART_ADDRESS_IDENTICAL, locale)}
                  </div>
                  <br />
                  <input
                    type="radio"
                    name="different"
                    className="radio-margin"
                    checked={!isAddressIdentical}
                    onChange={this.handleRadioClick.bind(this)}
                  />
                  <div className="radio-button-label">
                    {TT(LabelKeys.CART_ADD_NEW, locale)}
                  </div>
                </div>
                {!isAddressIdentical && (
                  <div class="invoice">
                    <form action="" className="invoice-address-block">
                      <div class="form-group">
                        <label
                          htmlFor="company"
                          class="form-label text-capitalize"
                        >
                          {TT(LabelKeys.ADDRESS_FIELD_COMPANY, locale)}
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          name="company"
                          id="company"
                          placeholder="Name of company"
                          onChange={this.handleInvoiceChange}
                          value={_get(invoiceAddress, "company")}
                        />
                      </div>

                      <div class="form-group">
                        <label
                          htmlFor="firstName"
                          class="form-label text-capitalize"
                        >
                          {TT(LabelKeys.ADDRESS_FIELD_FIRST_NAME, locale)}
                          <sup>*</sup>
                        </label>
                        <input
                          type="text"
                          class={
                            invoiceErrors.firstName.length > 0
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          name="firstName"
                          id="firstName"
                          onChange={this.handleInvoiceChange}
                          value={_get(invoiceAddress, "firstName")}
                        />
                        {invoiceErrors.firstName.length > 0 && (
                          <div class="invalid-feedback">
                            {invoiceErrors.firstName}
                          </div>
                        )}
                      </div>

                      <div class="form-group">
                        <label
                          htmlFor="surname"
                          class="form-label text-capitalize"
                        >
                          {TT(LabelKeys.ADDRESS_FIELD_LAST_NAME, locale)}{" "}
                          <sup>*</sup>
                        </label>
                        <input
                          type="text"
                          class={
                            invoiceErrors.lastName.length > 0
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          name="lastName"
                          id="lastName"
                          onChange={this.handleInvoiceChange}
                          value={_get(invoiceAddress, "lastName")}
                        />
                        {invoiceErrors.lastName.length > 0 && (
                          <div class="invalid-feedback">
                            {invoiceErrors.lastName}
                          </div>
                        )}
                      </div>

                      <div class="form-group">
                        <label
                          htmlFor="street"
                          class="form-label text-capitalize"
                        >
                          {TT(LabelKeys.ADDRESS_FIELD_LINE_1, locale)}{" "}
                          <sup>*</sup>
                        </label>
                        <input
                          type="text"
                          class={
                            invoiceErrors.streetName.length > 0
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          name="streetName"
                          id="streetName"
                          onChange={this.handleInvoiceChange}
                          value={_get(invoiceAddress, "streetName")}
                        />
                        {invoiceErrors.streetName.length > 0 && (
                          <div class="invalid-feedback">
                            {invoiceErrors.streetName}
                          </div>
                        )}
                      </div>

                      <div class="form-group">
                        <label
                          htmlFor="additionalStreetInfo"
                          class="form-label text-capitalize"
                        >
                          {TT(LabelKeys.ADDRESS_FIELD_LINE_2, locale)}{" "}
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          name="additionalStreetInfo"
                          id="additionalStreetInfo"
                          onChange={this.handleInvoiceChange}
                          value={_get(invoiceAddress, "additionalStreetInfo")}
                        />
                      </div>

                      <div class="form-group">
                        <label
                          htmlFor="city"
                          class="form-label text-capitalize"
                        >
                          {TT(LabelKeys.ADDRESS_FIELD_CITY, locale)}
                          <sup>*</sup>
                        </label>
                        <input
                          type="text"
                          class={
                            invoiceErrors.city.length > 0
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          name="city"
                          id="city"
                          onChange={this.handleInvoiceChange}
                          value={_get(invoiceAddress, "city")}
                        />
                        {invoiceErrors.city.length > 0 && (
                          <div class="invalid-feedback">
                            {invoiceErrors.city}
                          </div>
                        )}
                      </div>
                      <div class="form-group">
                        <label
                          htmlFor="postalCode"
                          class="form-label text-capitalize"
                        >
                          {TT(LabelKeys.ADDRESS_FIELD_POSTAL_CODE, locale)}
                          <sup>*</sup>
                        </label>
                        <input
                          type="text"
                          class={
                            invoiceErrors.postalCode.length > 0
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          name="postalCode"
                          id="postalCode"
                          onChange={this.handleInvoiceChange}
                          value={_get(invoiceAddress, "postalCode")}
                        />
                        {invoiceErrors.postalCode.length > 0 && (
                          <div class="invalid-feedback">
                            {invoiceErrors.postalCode}
                          </div>
                        )}
                      </div>

                      <div class="form-group">
                        <label
                          htmlFor="country"
                          class="form-label text-capitalize"
                        >
                          {TT(LabelKeys.ADDRESS_FIELD_COUNTRY, locale)}{" "}
                          <sup>*</sup>
                        </label>
                        <select
                          className="form-control"
                          name="country"
                          id="country"
                          value={selectedCountry}
                          onChange={this.handleCountryChange.bind(this)}
                        >
                          <option value="DE">Germany</option>
                          {/*CountryList && CountryList.map((item) => {
                          return <option value={item.code}>{item.name}</option>;
                        })*/}
                        </select>
                      </div>

                      <div class="form-group">
                        <label
                          htmlFor="phone"
                          class="form-label text-capitalize"
                        >
                          {TT(LabelKeys.ADDRESS_FIELD_PHONE, locale)}{" "}
                          <sup>*</sup>
                        </label>
                        <input
                          type="text"
                          class={
                            invoiceErrors.phone.length > 0
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          name="phone"
                          id="phone"
                          onChange={this.handleInvoiceChange}
                          value={_get(invoiceAddress, "phone")}
                        />
                        {invoiceErrors.phone.length > 0 && (
                          <div class="invalid-feedback">
                            {invoiceErrors.phone}
                          </div>
                        )}
                      </div>

                      <div class="form-group">
                        <label
                          htmlFor="phone"
                          class="form-label text-capitalize"
                        >
                          {TT(LabelKeys.ADDRESS_FIELD_EMAIL, locale)}{" "}
                          <sup>*</sup>
                        </label>
                        <input
                          type="email"
                          class={
                            invoiceErrors.email.length > 0
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          name="email"
                          id="email"
                          onChange={this.handleInvoiceChange}
                          value={_get(invoiceAddress, "email")}
                        />
                        {invoiceErrors.email.length > 0 && (
                          <div class="invalid-feedback">
                            {invoiceErrors.email}
                          </div>
                        )}
                      </div>
                      {/*
                  <div class="form-group">
                    <label htmlFor="UstIdnr" class="form-label text-capitalize">
                      UstIdnr.
                    </label>
                    <input
                      type="email"
                      class="form-control is-invalid"
                      name="UstIdnr"
                      id="UstIdnr"
                    />
                    <div class="invalid-feedback">* required fields</div>
                  </div>
                  
                  <div class="d-inline-flex w-100 justify-content-end">
                    <button class="btn btn-primary" >
                      Update
                    </button>
                  </div>
                  */}
                    </form>
                  </div>
                )}

                <div className="p-3 cart-total-info mt-2">
                  <h6>{TT(LabelKeys.CART_TOTAL_HEADING, locale)}</h6>
                  <ul>
                    <li className="d-flex justify-content-between px-1">
                      <span>{TT(LabelKeys.CART_SUBTOTAL, locale)}</span>
                      <span>
                        <strong>
                          &euro;{" "}
                          {price
                            ? Number(_get(price, "totalNet.centAmount")) / 100
                            : 0}
                        </strong>
                      </span>
                    </li>
                    <li className="d-flex justify-content-between px-1">
                      <span>
                        {TT(LabelKeys.CART_TAX_RATE, locale)} (
                        {_get(price, "taxPortions", []).reduce((acc, cur) => {
                          return acc + cur.rate || 0;
                        }, 0) * 100}
                        %)
                      </span>
                      <span>
                        &euro;{" "}
                        {_get(price, "taxPortions", []).reduce((acc, cur) => {
                          return acc + cur.amount.centAmount || 0;
                        }, 0) / 100}
                      </span>
                    </li>
                  </ul>
                  <div className="d-flex justify-content-between py-3 px-1 cart-total-box">
                    <span>{TT(LabelKeys.CART_TOTAL, locale)}</span>
                    <span>
                      <strong>
                        &euro;{" "}
                        {price
                          ? Number(_get(price, "totalGross.centAmount")) / 100
                          : 0}
                      </strong>
                    </span>
                  </div>
                </div>
                <div className="p-3 cart-total-info">
                  <h6>{TT(LabelKeys.CART_PAYMENT_METHOD, locale)}</h6>
                  <div className="methods">
                    <div className=" mt-3">
                      <div className="">
                        <div className="methods-items">
                          <div className="radio">
                            <input
                              type="radio"
                              id="paypal"
                              name="payment"
                              value={PAYMENTS.PAYPAL}
                              checked={
                                selectedPaymentMethod === PAYMENTS.PAYPAL
                              }
                              onChange={this.handlePaymentRadioClick.bind(this)}
                            />
                            <label for="paypal">
                              <span className="circle"></span>
                              <div className="img-set">
                                <img src={paypalImage} alt="" />
                              </div>
                              <p>
                                <span className="title">Paypal</span>
                              </p>
                            </label>
                          </div>
                        </div>
                        <div
                          class={` mt-2 mb-2 w-100 justify-content-end collapse
                              ${
                                selectedPaymentMethod === PAYMENTS.PAYPAL
                                  ? "d-inline-flex show"
                                  : ""
                              }`}
                        >
                          <div id="paypal-button-container"></div>
                        </div>
                      </div>
                      <div>
                        <div className="methods-items">
                          <div className="radio">
                            <input
                              type="radio"
                              id="onAccount"
                              name="payment"
                              value={PAYMENTS.ONACCOUNT}
                              checked={
                                selectedPaymentMethod === PAYMENTS.ONACCOUNT
                              }
                              onChange={this.handlePaymentRadioClick.bind(this)}
                            />
                            <label for="onAccount">
                              <span className="circle"></span>
                              <p>
                                <span className="title">
                                  {TT(LabelKeys.CART_ON_ACCOUNT, locale)}
                                </span>
                              </p>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class={`mt-2 mb-2 w-100 justify-content-end collapse ${
                        selectedPaymentMethod === PAYMENTS.ONACCOUNT
                          ? "d-inline-flex show"
                          : ""
                      }`}
                    >
                      <button
                        type="submit"
                        class="btn custom-button-payment"
                        onClick={this.placeOrder.bind(this)}
                      >
                        {isCheckOutLoading ? (
                          <i className="fas fa-circle-notch fa-spin"></i>
                        ) : (
                          "Checkout"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function CartWrapper(props) {
  const { locale } = useLocaleContext();
  const { storefrontWebAuth, isSignedIn }  = Hooks.useAuth()
  const shopperProfile = storefrontWebAuth.getProfile() || {}
  return <Cart locale={locale} isSignedIn={isSignedIn} customerEmail={shopperProfile.email} {...props}></Cart>;
}

export default CartWrapper;
