import React from "react";
import ProductMenu from "./product-menu";
import SubCategoryMenu from "./sub-category";

const CategoryDropDown = ({ categoryData = {}, children }) => {
  return (
    <div
      className="header-box collapse fade"
      id="menu-box"
      data-parent="#mainHeader"
    >
      <div className="header-box-body">
        <div className="container">
          <h5 className="mobile-menu-product-title">{children}</h5>

          <ul className="mobile-menu">
            <SubCategoryMenu subCategories={categoryData.subCategory} />
            <ProductMenu products={categoryData.products} />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CategoryDropDown;
