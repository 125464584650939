import _get from "lodash.get";
import { useEffect, useState } from "react";
import ApiUtils from "../utils/api-utils";

const useCarousel = (locale) => {
  const [isLoading, setIsLoading] = useState(false);
  const [carousel, setCarousel] = useState();
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const results = await ApiUtils.doQuery(
          ApiUtils.queries.carousel(locale),
          ""
        );
        if (_get(results, "data")) {
          setCarousel(_get(results, "data.homePage.imageCarousel", []));
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [locale]);
  return { carousel, setCarousel, isLoading };
};

export default useCarousel;
