const germanTranslation = {
  "About Us": "Impressum",
  Account: "Konto",
  "Add new address": "Neue adresse hinzufügen",
  "Address Line 1": "Anschrift Zeile 1",
  "Address Line 2": "Adresszeile 2",
  "Cart total": "Wagen insgesamt",
  City: "Stadt",
  Company: "Unternehmen",
  "Congratulation!": "Glückwunsch!",
  "Continue shopping": "Mit dem Einkaufen fortfahren",
  Country: "Land",
  "Customer-ID:": "Kundennummer:",
  Email: "Email",
  'Email:': "Email",
  'Name:': "Name",
  "First name": "Vorname",
  "Forgot your password?": "Haben Sie Ihr Passwort vergessen?",
  "Haven't ordered with us before?": "Noch nicht bei uns bestellt?",
  "Help Center": "Hilfezentrum",
  Impressum: "Impressum",
  "Invoice address": "Rechnungsanschrift",
  "Invoice address is identical to shipping address":
    "Die Rechnungsadresse ist identisch mit der lieferadresse",
  "Last Name": "Familienname, Nachname",
  Login: "Anmeldung",
  Logout: "Ausloggen",
  "My Account": "Mein Konto",
  "Order review": "Bestellüberprüfung",
  "PRODUCT OVERVIEW QUADRIFID": "PRODUKTÜBERSICHT QUADRIFID",
  Payment: "Zahlung",
  "Payment Method": "Bezahlverfahren",
  Phone: "Telefon",
  "Postal code": "Postleitzahl",
  Products: "Produkte",
  Register: "Registrieren",
  "Login/Register": "Anmeldung/Registrieren",
  Search: "Suche",
  Service: "Bedienung",
  Shipping: "Versand",
  "Shipping address": "Lieferanschrift",
  "Social Media": "Sozialen Medien",
  Subtotal: "Zwischensumme",
  "Tax Rate": "Steuersatz",
  "Tel:": "Tel",
  "Terms and Conditions": "Geschäftsbedingungen",
  Total: "Gesamt",
  "You have successfully completed your purchase.":
    "Sie haben Ihren Kauf erfolgreich abgeschlossen.",
  "Your Account": "Ihr Konto",
  "Your Order-No:": "Ihre Bestellnummer:",
  "Your Profile": "Dein Profil",
  "Logged in successfully!": "Erfolgreich eingeloggt!",
  "Login failed. Please try again later": "Anmeldung fehlgeschlagen. Bitte versuchen Sie es später noch einmal",

  "The order creation is in progress": "Die Auftragserstellung ist im Gange",
  "It may take 1-2 minutes, please do not close the browser":
    "Es kann 1-2 Minuten dauern, bitte verweisen  Sie nicht auf den Browser",
  "Your shopping cart": "Dein Einkaufswagen",
  // "Continue shopping": "Mit dem Einkaufen fortfahren",
  Article: "Artikel",
  Quantity: "Menge",
  // Total: "Gesamt",
  Remove: "Entfernen",
  "Cart Total": "Warenkorb insgesamt",
  // Subtotal: "Zwischensumme",
  // Total: "Gesamt",
  Tax: "MwSt",
  Checkout: "Auschecken",

  "Buy personalised products online":
    "Kaufen Sie personalisierte Produkte online",
  "Review Cart": "Überprüfen Sie den Warenkorb",
  Cart: "Wagen",
  "Privacy Policy": "Datenschutz-Bestimmungen",
  // "Terms and Conditions": "Geschäftsbedingungen",
  "Processing Payment": "Zahlung verarbeiten",
  "Order placed successfully": "Bestellung erfolgreich aufgegeben",
  "On Account": "Auf Rechnung",
  Description: "Beschreibung",
  "Design Online": "Online gestalten",
  Upload: "hochladen",
  Configurations: "Konfigurationen",
  "Please select the configurations on the left to view the price":
    "Bitte wählen Sie die Konfigurationen links aus, um den Preis anzuzeigen",
};

export default germanTranslation;
