/* eslint-disable */
import { useEffect, useState } from "react";
import ApiUtils from "../utils/api-utils";
import _get from "lodash.get";

const useProductDetails = (productId, locale) => {
  const [isLoading, setIsLoading] = useState(false);
  const [storefrontId, setStorefrontId] = useState();
  const [businessAccountId, setBusinessAccountId] = useState();
  const [productDetails, setProductDetails] = useState();

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const results = await ApiUtils.doQuery(
          ApiUtils.queries.fetchProductDetail(locale),
          { id: productId }
        );
        if (_get(results, "data")) {
          setIsLoading(false);
          setStorefrontId(_get(results, "data.storefrontConfig.storefrontId"));
          setBusinessAccountId(
            _get(results, "data.storefrontConfig.accountId")
          );
          setProductDetails(_get(results, "data.details[0]", {}));
        }
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, [productId, locale]);
  return { storefrontId, productDetails, isLoading, businessAccountId };
};

export default useProductDetails;
