import _get from "lodash.get";
import React from "react";
import { Link } from "react-router-dom";
import { LabelKeys } from "../config/locale/label-config";
import { TT } from "../config/locale";
import { useLocaleContext } from "../context/localeContext";
import Hooks from "../hooks";
import ProductImage from "../images/no-image.png";
// import { GenericUtil } from "../utils";

const Home = () => {
  const { locale } = useLocaleContext();
  document.title = TT(LabelKeys.TITLE_HOME, locale);
  let { products } = Hooks.useHomePage(locale);
  const { carousel } = Hooks.useCarousel(locale);
  const { teasers } = Hooks.useTeaser(locale);
  const { singleImageCard } = Hooks.useSingleImageCard(locale);
  const {
    highlightedCarouselCard,
    showHighlightedCarouselCard,
  } = Hooks.useHighlightedCarouselCard(locale);
  products = products?.sort((a, b) => {
    return parseInt(a.cardNumber) - parseInt(b.cardNumber);
  });
  return (
    <div>
      <div
        id="carouselExampleIndicators"
        className="carousel slide slider-index"
        data-ride="carousel"
      >
        <ol className="carousel-indicators">
          <li
            data-target="#carouselExampleIndicators"
            data-slide-to="0"
            className="active"
          ></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        </ol>
        <div className="carousel-inner">
          {carousel &&
            carousel.map((item, index) => {
              return (
                <div
                  className={
                    index === 0 ? "carousel-item active" : "carousel-item"
                  }
                  key={_get(item, "heading1")}
                  style={{
                    backgroundImage: `url(${_get(
                      item,
                      "backgroundImage.url"
                    )})`,
                  }}
                >
                  {/*
                  <img
                    src={_get(item, "backgroundImage.url")}
                    className="d-block"
                    alt="img"
                  />
                  */}
                  <div className="carousel-caption d-none d-md-block">
                    <div className="content">
                      <h1>{_get(item, "heading1")}</h1>
                      <p>{_get(item, "heading2")}</p>
                      {_get(item, "landingScreen.__typename") ===
                      "ProductRecord" ? (
                        <Link
                          to={`/detail/${_get(item, "landingScreen.id", "")}`}
                        >
                          <button
                            type="button"
                            className="btn btn-secondary-custom mt-5"
                          >
                            {_get(item, "buttonName")}
                          </button>
                        </Link>
                      ) : (
                        <a
                          href={_get(item, "landingScreen.externalUrl")}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button
                            type="button"
                            className="btn btn-secondary-custom mt-5"
                          >
                            {_get(item, "buttonName")}
                          </button>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <a
          className="carousel-control-prev"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <i className="fal fa-chevron-left" aria-hidden="true"></i>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <i className="fal fa-chevron-right" aria-hidden="true"></i>
          <span className="sr-only">Next</span>
        </a>
      </div>
      {teasers && (
        <div className="container mt-5 mb-2">
          <div className="row">
            {teasers.map((item) => {
              return (
                <div className="col-12 col-md-4">
                  <div className="text-box">
                    <div className="text-box-title">
                      <h2
                      // title={_get(item, "title")}
                      >
                        {/* {GenericUtil.limitWord(
                          _get(item, "title", TEASER_DEFAULT_VALUES.title),
                          21
                        )} */}
                        {_get(item, "title", "")}
                      </h2>
                    </div>
                    <div className="text-box-content">
                      <p
                      // title={_get(item, "description")}
                      >
                        {/* {GenericUtil.limitWord(
                          _get(
                            item,
                            "description",
                            TEASER_DEFAULT_VALUES.title
                          ),
                          240
                        )} */}
                        {_get(item, "description", "")}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <section className="product-overview-four-col mt-6">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="text-center section-text-container">
                <h2 className="section-title text-uppercase">
                  {TT(LabelKeys.HOME_PAGE_PRODUCT, locale)}
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            {products &&
              products.map((product, index) => {
                return (
                  <div
                    className="col-lg-3 col-md-6 col-6 mb-40"
                    key={"product-tile-" + index}
                  >
                    <a
                      href={`/detail/${product.chooseProduct.id}`}
                      rel="norefferer"
                      className="text-decoration-none"
                    >
                      <div className="product-wrapper">
                        <div className="product-img">
                          <img
                            src={_get(
                              product,
                              "chooseProduct.assets[0].url",
                              ProductImage
                            )}
                            className="img-fluid"
                            alt="product"
                          />
                        </div>
                        <div className="product-desc">
                          <span>
                            {/* {GenericUtil.limitWord(
                              _get(product, "chooseProduct.name"),
                              35
                            )} */}
                            {_get(product, "chooseProduct.name")}
                          </span>
                          <p>
                            {/* {GenericUtil.limitWord(
                              _get(
                                product,
                                "chooseProduct.shortDescription",
                                "--"
                              ),
                              110
                            )}*/}
                            {_get(
                              product,
                              "chooseProduct.shortDescription",
                              "--"
                            )}
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
      <div className="container mt-5 mb-5">
        {singleImageCard &&
          _get(singleImageCard, "singleImgCardShowOnHomePage", false) && (
            <div className="img-text-box">
              <div className="img-text-box-figure">
                <img
                  src={_get(
                    singleImageCard,
                    "singleImgCardImage.url",
                    ProductImage
                  )}
                  alt=""
                />
              </div>
              <div className="img-text-box-content mt-5 text-block-w">
                <div
                  className="row"
                  style={{
                    alignSelf: "stretch",
                  }}
                >
                  <div className="col-md-12">
                    <h5
                    // title={_get(singleImageCard, "singleImgCardTitle")}
                    >
                      {/* {GenericUtil.limitWord(
                        _get(
                          singleImageCard,
                          "singleImgCardTitle",
                          SINGLE_IMAGE_CARD_DEFAULT_VALUES.TITLE
                        ),
                        100
                      )} */}
                      {_get(singleImageCard, "singleImgCardTitle", "")}
                    </h5>
                  </div>
                  <div className="col-12 col-md-6">
                    <p
                      className="w-100"
                      // title={_get(singleImageCard, "singleImgCardDescription1")}
                    >
                      {/* {GenericUtil.limitWord(
                        _get(
                          singleImageCard,
                          "singleImgCardDescription1",
                          SINGLE_IMAGE_CARD_DEFAULT_VALUES.DESC
                        ),
                        275
                      )} */}
                      {_get(singleImageCard, "singleImgCardDescription1", "")}
                    </p>
                  </div>
                  <div className="col-12 col-md-6">
                    <p
                      className="w-100"
                      // title={_get(singleImageCard, "singleImgCardDescription2")}
                    >
                      {/* {GenericUtil.limitWord(
                        _get(
                          singleImageCard,
                          "singleImgCardDescription2",
                          SINGLE_IMAGE_CARD_DEFAULT_VALUES.DESC
                        ),
                        275
                      )} */}
                      {_get(singleImageCard, "singleImgCardDescription2", "")}
                    </p>
                  </div>
                  <div className="text-center w-100 mt-3">
                    <Link
                      to={`/detail/${_get(
                        singleImageCard,
                        "singleImgCardLandingScreen.id",
                        ""
                      )}`}
                      className="text-decoration-none"
                    >
                      <button className="btn btn-secondary-custom">
                        {_get(singleImageCard, "buttonName", "")}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
      {highlightedCarouselCard && showHighlightedCarouselCard && (
        <div className="container mt-6 mb-5">
          <div
            id="productSlider"
            className="carousel slide product-slider"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              {highlightedCarouselCard.map((item, index) => {
                if (index % 2 === 0)
                  return (
                    <div
                      className={
                        index === 0 ? "carousel-item active" : "carousel-item"
                      }
                    >
                      <div className="text-block-w">
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <div className="card">
                              <Link
                                to={`/detail/${_get(
                                  item,
                                  "landingScreen.id",
                                  ""
                                )}`}
                              >
                                <img
                                  src={item.highlightedImage.url}
                                  className="card-img-top"
                                  alt="img"
                                  height="385"
                                />
                              </Link>
                              {/* <i className="fal fa-arrow-right icon-arrow"></i> */}
                              <div className="card-body">
                                <h5
                                  className="card-title"
                                  // title={_get(item, "title", "")}
                                >
                                  {/* {GenericUtil.limitWord(
                                    _get(item, "title", ""),
                                    50
                                  )} */}
                                  {_get(item, "title", "")}
                                </h5>
                                <p
                                  className="card-text"
                                  // title={_get(item, "description", "")}
                                >
                                  {/* {GenericUtil.limitWord(
                                    _get(item, "description", ""),
                                    250
                                  )} */}
                                  {_get(item, "description", "")}
                                </p>
                              </div>
                            </div>
                          </div>
                          {highlightedCarouselCard[index + 1] && (
                            <div className="col-12 col-md-6">
                              <div className="card">
                                <Link
                                  to={`/detail/${_get(
                                    highlightedCarouselCard[index + 1],
                                    "landingScreen.id",
                                    ""
                                  )}`}
                                >
                                  <img
                                    src={_get(
                                      highlightedCarouselCard[index + 1],
                                      "highlightedImage.url"
                                    )}
                                    className="card-img-top"
                                    alt="img"
                                    height="385"
                                  />
                                  {/* <i className="fal fa-arrow-right icon-arrow"></i> */}
                                </Link>
                                <div className="card-body">
                                  <h5
                                    className="card-title"
                                    // title={_get(
                                    //   highlightedCarouselCard[index + 1],
                                    //   "title",
                                    //   ""
                                    // )}
                                  >
                                    {/* {GenericUtil.limitWord(
                                      _get(
                                        highlightedCarouselCard[index + 1],
                                        "title",
                                        ""
                                      ),
                                      50
                                    )} */}
                                    {_get(
                                      highlightedCarouselCard[index + 1],
                                      "title",
                                      ""
                                    )}
                                  </h5>
                                  <p
                                    className="card-text"
                                    // title={_get(
                                    //   highlightedCarouselCard[index + 1],
                                    //   "description",
                                    //   ""
                                    // )}
                                  >
                                    {/* {GenericUtil.limitWord(
                                      _get(
                                        highlightedCarouselCard[index + 1],
                                        "description",
                                        ""
                                      ),
                                      250
                                    )} */}
                                    {_get(
                                      highlightedCarouselCard[index + 1],
                                      "description",
                                      ""
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                return <></>;
              })}

              {/* <div className="carousel-item">
              <div className="text-block-w">
                <div className="row row-eq"></div>
              </div>
            </div> */}
            </div>
            <a
              className="carousel-control-prev"
              href="#productSlider"
              role="button"
              data-slide="prev"
            >
              <i className="fal fa-chevron-left" aria-hidden="true"></i>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#productSlider"
              role="button"
              data-slide="next"
            >
              <i className="fal fa-chevron-right" aria-hidden="true"></i>
              <span className="sr-only">Next</span>
            </a>
            <ol className="carousel-indicators">
              <li
                data-target="#productSlider"
                data-slide-to="0"
                className="active"
              ></li>
              <li
                data-target="#productSlider"
                data-slide-to="1"
                className=""
              ></li>
            </ol>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
