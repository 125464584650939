import React, { useState, useEffect } from "react";
import "./styles/index.scss";
import ApiUtils from "./utils/api-utils";
import Routes from "./routes";
import { CartProvider } from "./context/cartContext";
import ErrorBoundary from "./components/errorBoundary";
import { AuthProvider } from './hooks/useAuth'
import { LocaleProviderInit } from "./context/localeContext";
import { GenericUtil } from "./utils";

const DEFAULT_FAVICON =
  "https://us.123rf.com/450wm/pavelstasevich/pavelstasevich1811/pavelstasevich181101028/112815904-stock-vector-no-image-available-icon-flat-vector-illustration.jpg?ver=6";

function App(props) {
  const [isLoading, setIsLoading] = useState(false);
  const getTheme = async () => {
    try {
      setIsLoading(true);
      const results = await ApiUtils.doQuery(ApiUtils.queries.theme, "");
      setIsLoading(false);
      if (results.data) {
        console.log("theme", results.data);
        document.documentElement.style.setProperty(
          "--primary",
          results.data.logoTheme.primaryColor.hex
        );
        document.documentElement.style.setProperty(
          "--secondary",
          results.data.logoTheme.accentColor.hex
        );
      } else {
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const getFavIcon = async () => {
    try {
      const faviconElement = document.getElementById("favicon");
      faviconElement.href = "";
      const results = await ApiUtils.doQuery(ApiUtils.queries.fetchLogoUrl, "");
      if (results.data) {
        faviconElement.href = results.data.logoTheme.favIcon.url;
      } else {
        faviconElement.href = DEFAULT_FAVICON;
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getTheme();
    getFavIcon();
  }, []);

  return (
    <div className="App">
      {isLoading ? (
        "Loading..."
      ) : (
        <ErrorBoundary>
          <LocaleProviderInit locale={GenericUtil.getLanguage()}>
            <AuthProvider storefrontWebAuth={props.storefrontWebAuth}>
              <CartProvider>
                <Routes />
              </CartProvider>
            </AuthProvider>
          </LocaleProviderInit>
        </ErrorBoundary>
      )}
    </div>
  );
}

export default App;
