import React from 'react';
import ReactDOM from 'react-dom';
import StorefrontAuth from "@storefront/auth-js";
import ApiUtils from "./utils/api-utils"
import { GenericUtil } from "./utils";
import { LOGIN_LOCALE_MAPPER } from "./config/app-config"
import './index.css';
import App from './App';


const rootElement = document.getElementById('root')
const Root = (props) => <React.StrictMode><App {...props}/></React.StrictMode>

//Workaround until we create a connection in Cimpress tenant
const overrides = {
  connection: 'WMD-Customers-Dev',
  audience: 'https://development.api.cimpress.io/',
  client_id: '0J6sfxPc4HOB3X7qKnNBne9D59uiM4e1',
  domain: 'cimpress-dev.auth0.com'
}

const initializeAuth = async () => {
  try {
    const results = await ApiUtils.doQuery(ApiUtils.queries.storefrontConfig, "");
    if(results.data) {
      const storefrontId =  results.data.storefrontConfig.storefrontId;
      console.log(storefrontId)
      StorefrontAuth.init({
        // storeFrontId: '4d932e7b-fb42-409b-8eba-31fc389b5948',
        storeFrontId: storefrontId,
        culture: LOGIN_LOCALE_MAPPER[GenericUtil.getLanguage() || 'de'],
        ...overrides
      }, ({ webAuth: storefrontWebAuth }) => ReactDOM.render( <Root storefrontWebAuth={storefrontWebAuth}/>, rootElement))
    }
    else {
      alert('Storefront not configured correctly')
    }
  }
  catch(err){
    alert('Something went wrong while initializing Auth: ' + err)
  }
}

initializeAuth()


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
